import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildConfigurationListsBaseUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { List, Lists } from '../types';

export const loadLists = (
    actionName: string,
    processResultFunction: Function,
): Observable<{ result: List[]; actionName: string; processResultFunction: Function }> => {
    const url = buildConfigurationListsBaseUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<Lists>) => {
            const { List } = response;
            const result = convertToCamel<List[]>(List);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
